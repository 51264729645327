<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    title="出入金"
    width="400px"
    class="test-account-dialog"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="账户">
        <span>{{rowData.login}}</span>
      </el-form-item>
      <el-form-item label="余额">
        <span>{{rowData.balance || ''}}</span>
      </el-form-item>
      <el-form-item label="净值">
        <span>{{rowData.equity || ''}}</span>
      </el-form-item>
      <el-form-item label="操作">
        <el-radio
          v-model="form.type"
          :label="1"
        >入金</el-radio>
        <el-radio
          v-model="form.type"
          :label="2"
        >出金</el-radio>
      </el-form-item>
      <el-form-item prop="amount">
        <div class="input-box pr">
          <el-input
            class="money-input"
            v-model="form.amount"
            placeholder="请输入整数"
          ></el-input>
          <span class="sufxx pa">$</span>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button type="primary" @click="doAdd">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { testDeposit } from '@/api/ib/testAccount'
  import { noZeroInit } from '@/utils/validate'
export default {
  props: {
    dialogShow: {
      type: Boolean,
      required: false,
    },
    rowData:{},
  },
  data() {
    let moenyValidate = (rule,value,callback) => {
      if(!value){
        callback(new Error('请输入金额'));
      }else {
        if(!noZeroInit(value)){
          callback(new Error('请输入正整数'));
        }else {
          callback();
        }
      }
    }
    return {
      rules:{
        amount:[
          { validator: moenyValidate, trigger: 'blur' },
        ]
      },
      balance: '',
      form: {
        type: 1,
        amount: '',
      }
    }
  },
  methods: {
    close(){
      this.$emit('close-dialog');
      this.form={
        type: 1,
        amount: '',
      }
    },
    beforeCloseHandle(){
      this.close();
    },
    cancel() {
      this.close();
    },
    doAdd() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
            login: this.rowData.login,
          }
          testDeposit(params).then(res => {
            this.$emit('add-success');
            this.close();
            this.$notify({
              title: '操作成功',
              type: 'success',
              duration: 2500
            })
          }).catch(err => {
            console.log(err.response.data.message)
          })
        }
      })
    },
  }
}
</script>
<style lang="less">
  .test-account-dialog {
    .el-input__inner {
      padding-left: 20px;
    }
  }
</style>
<style lang="less" scoped>
.test-account-dialog {
  .input-box {
    position: relative;
    .sufxx {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
  }
}
</style>
