<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="title"
    width="400px"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="login">
        <el-input placeholder="请输入MT4账户" v-model="form.login"></el-input>
      </el-form-item>
      <el-form-item prop="usedBy">
        <el-input placeholder="使用人" v-model="form.usedBy"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button type="primary" @click="doHandle">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/ib/testAccount'
export default {
  props: {
    dialogShow: {
      type: Boolean,
      required: false,
    },
    rowData:{
      type: Object,
      default(){
        return {
          login: '',
          usedBy: '',
        }
      }
    },
  },
  data() {
    return {
      rules:{
        login:[
          { required: true, message: '请输入mt4账户', trigger: 'blur' },
        ],
        usedBy:[
          { required: true, message: '请输入使用人', trigger: 'blur' },
        ],
      },
      form: {
        login: '',
        usedBy: '',
        id: '',
      }
    }
  },
  watch:{
    rowData(val){
      if(val){
        this.form.login = val.login;
        this.form.usedBy = val.usedBy;
        this.form.id = val.id;
      }
    },
  },
  computed: {
    title() {
      if(!this.form.id){
        return '新增用户';
      }else {
        return '编辑用户';
      }
    }
  },
  methods: {

    close(){
      this.$emit('close-dialog');
      this.form={
        login: '',
        usedBy: '',
        id: '',
      }
    },
    beforeCloseHandle(){
      this.close();
    },
    cancel() {
      this.close();
    },
    doHandle() {

      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(!this.form.id){
            add(this.form).then(res => {
              this.$emit('add-success');
              this.close();
              this.$notify({
                title: '添加成功',
                type: 'success',
                duration: 2500
              })
            }).catch(err => {
              console.log(err.response.data.message)
            })
          }else {
            edit(this.form).then(res => {
              this.$emit('add-success');
              this.close();
              this.$notify({
                title: '编辑成功',
                type: 'success',
                duration: 2500
              })
            }).catch(err => {
              console.log(err.response.data.message)
            })
          }
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
