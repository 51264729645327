<template>
  <div class="account-addaccount">
    <div class="header clearfix head-container">
      <el-input
          class="filter-item"
          style="width: 130px;"
          size='mini'
          v-model="mt4"
          placeholder="MT4账户"
          @keyup.enter.native="toQuery"
          clearable
      ></el-input>
      <el-input
          clear
          class="filter-item"
          style="width: 130px;" size='mini'
          v-model="userBy"
          placeholder="使用人"
          @keyup.enter.native="toQuery"
          clearable
      ></el-input>
      <el-button icon="el-icon-search" class="filter-item" size='mini' type='primary' v-if="checkPermission(['ADMIN','TESTACCOUNT_ALL','TESTACCOUNT_ADD'])" @click="toQuery">搜索</el-button>
      <el-button type='primary' size='mini' icon="el-icon-plus" v-if="checkPermission(['ADMIN','TESTACCOUNT_ALL','TESTACCOUNT_ADD'])" class="add-btn filter-item" @click="addHandle">增加</el-button>
    </div>
    <!--表格渲染-->
    <el-table
      border
      v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="login" label="MT4账户"/>
      <el-table-column prop="balance" label="余额"/>
      <el-table-column prop="equity" label="净值"/>
      <el-table-column prop="usedBy" label="使用人"/>
      <el-table-column prop="addUser" label="添加人"/>
      <el-table-column prop="login" label="添加时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="scope">
          <el-button
                  size='mini'
                  type='success'
                  class=""
                  :class="{'margin-right10': !getEditBtnShow(scope.row)}"
                  v-if="checkPermission(['ADMIN','TESTACCOUNT_ALL','TESTACCOUNT_ADD'])"
                  @click="depositHandle(scope.row)"
          >出入金</el-button>
<!--系统添加的不能编辑 todo-->
          <el-button
              v-if="getEditBtnShow(scope.row)"
              size="mini"
              type="success"
              @click="editHandle(scope.row)"
              class="margin-right10"
          >编辑</el-button>
          <el-popover
            v-if="checkPermission(['ADMIN','TESTACCOUNT_ALL','TESTACCOUNT_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="deleteHandle(scope.row.id)">确定</el-button>
            </div>
            <el-button class="my-button" slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-dialog
      :dialog-show="dialogShow"
      @close-dialog="dialogShow = false"
      @add-success="addSuccess"
      :row-data="rowData"
    ></add-or-edit-dialog>
    <deposit-dialog
      :dialog-show="depositDialogShow"
      @close-dialog="depositDialogShow = false"
      @add-success="addSuccess"
      :row-data="rowData"
    ></deposit-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import { parseTime } from '@/utils/index'
import initData from '@/mixins/initData'
import addOrEditDialog from '@/components/customer/testAccount/form'
import depositDialog from '@/components/customer/testAccount/depositDialog'
import { del } from '@/api/ib/testAccount'
export default {
  name:'testAccount',
  components: {
    addOrEditDialog,
    depositDialog,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      dialogShow: false,
      depositDialogShow: false,
      mt4: '',
      userBy: '',
      rowData: {},
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/testAccount'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort };
      if(this.mt4){
        this.params.login = this.mt4;
      }
      if(this.userBy){
        this.params.usedBy = this.userBy;
      }
      return true
    },
    deleteHandle(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
      })
    },
    addHandle(){
      this.rowData = {login: '',usedBy: '',id: '' };
      this.dialogShow = true;
    },
    addSuccess(){
      this.init();
    },
    getEditBtnShow(row){
      return row.addUser !== 'System';
    },
    editHandle(row){
      this.rowData = row;
      this.dialogShow = true;
    },
    toQuery(){
      this.page = 0;
      this.init();
    },
    depositHandle(row){
      this.rowData = row;
      this.depositDialogShow = true;
    }
  }
}
</script>

<style lang="less" scoped>
.account-addaccount {
  padding: 20px;
  .header {
    margin-bottom: 10px;;
  }
}
</style>
